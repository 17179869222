import React, { useContext, useState, useEffect, useRef } from "react";
import "./App.scss";
import Sidebar from "./Components/Sidebar/Sidebar";
import { TfiMenuAlt } from "react-icons/tfi";
import FileViewer from "./Components/FileViewer/FileViewer";
import AuthContainer from "./Components/AuthContainer/AuthContainer.jsx";
import ChatContainer from "./Components/ChatContainer/ChatContainer.jsx";
import MailChatContainer from "./Components/ChatContainer/MailChatContainer.jsx";
import OnboardingGuide from "./Components/OnboardingGuide/OnboardingGuide.jsx";
import MaintenancePage from "./Components/MaintenancePage/MaintenancePage.jsx";
import { AuthContext, AuthProvider } from "./Contexts/AuthContext.jsx";
import { chatApi } from "./api/chatApi.jsx";

function AppContent() {
  const { isAuthenticated } = useContext(AuthContext); // ✅ AuthContext 사용

  const [mainError, setMainError] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDevMode, setIsDevMode] = useState(false);
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [isRAG, setIsRAG] = useState(true);
  const [showGuide, setShowGuide] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [currentMessages, setCurrentMessages] = useState(null); // chathistory 불러왔을 경우, 현재 표시해야 될 것

  const controllerRef = useRef(null);

  useEffect(() => {
    // 가이드팝업창
    const shouldShowPopup = localStorage.getItem("hideGuidePopup") !== "true";
    setShowGuide(shouldShowPopup);
  }, []);

  // ** chat history ** //
  // session 정보 불러오기
  const fetchSession = async (userId) => {
    try {
      const data = await chatApi.getSessions();
      setSessions(data);
    } catch (error) {
      console.error("Failed to fetch session:", error);
    }
  };

  // session 정보로 chat history 불러오기
  const fetchChatHistory = async (session) => {
    try {
      const data = await chatApi.getMessages(session.id);
      setCurrentMessages({
        session,
        messages: data,
      });
    } catch (error) {
      console.error("Failed to fetch chat history:", error);
    }
  };

  // ** 스트림 중지 함수 ** //
  const stopGenerating = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
      setIsGenerating(false);
    }
  };

  // ** OnboardingGuide ** //
  const handleGuideComplete = () => {
    setShowGuide(false);
    // localStorage.setItem("guideCompleted", "true");
  };

  const handleOpenGuide = () => {
    setShowGuide(true);
  };

  // ** PDF sidebar ** //
  const handleOpenFile = (fileUrl, fileName, pages) => {
    setCurrentFile({ url: fileUrl, name: fileName, pages: pages });
    setIsFileViewerOpen(true);
  };

  const handleCloseFile = () => {
    setIsFileViewerOpen(false);
  };

  // ** Inquiry sidebar ** //
  const openSidebar = () => {
    setIsSidebarOpen(true);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // ** switch to devMode ** //
  const handleDevMode = () => {
    setIsDevMode(!isDevMode);
  };

  // ** switch to MailChatContainer ** //
  const handleOpenMailChat = () => {
    setIsRAG(false);
  };

  const handleOpenRAGChat = () => {
    setIsRAG(true);
  };

  return (
    <div>
      {!isAuthenticated ? (
        <AuthContainer mainError={mainError} />
      ) : (
        <div
          className={`app-container ${
            isFileViewerOpen ? "file-viewer-open" : ""
          }`}
        >
          {isSidebarOpen && (
            <Sidebar
              toggleSidebar={closeSidebar}
              isRAG={isRAG}
              handleOpenRAGChat={handleOpenRAGChat}
              handleOpenMailChat={handleOpenMailChat}
              sessions={sessions}
              fetchChatHistory={fetchChatHistory}
            />
          )}
          {!isSidebarOpen && (
            <button onClick={openSidebar} className="open-sidebar">
              <TfiMenuAlt />
            </button>
          )}
          {showGuide && (
            <OnboardingGuide
              onComplete={handleGuideComplete}
              startRAG={handleOpenRAGChat}
              startMail={handleOpenMailChat}
            />
          )}
          {isRAG ? (
            <ChatContainer
              isSidebarOpen={isSidebarOpen}
              isDevMode={isDevMode}
              handleDevMode={handleDevMode}
              handleOpenFile={handleOpenFile}
              isRAG={isRAG}
              handleOpenRAGChat={handleOpenRAGChat}
              handleOpenMailChat={handleOpenMailChat}
              handleOpenGuide={handleOpenGuide}
              showGuide={showGuide}
              currentMessages={currentMessages}
            />
          ) : (
            <MailChatContainer
              isSidebarOpen={isSidebarOpen}
              isDevMode={isDevMode}
              handleDevMode={handleDevMode}
              handleOpenFile={handleOpenFile}
              isRAG={isRAG}
              handleOpenRAGChat={handleOpenRAGChat}
              handleOpenMailChat={handleOpenMailChat}
              handleOpenGuide={handleOpenGuide}
            />
          )}

          {isFileViewerOpen && (
            <FileViewer
              fileUrl={currentFile.url}
              fileName={currentFile.name}
              pages={currentFile.pages}
              isOpen={isFileViewerOpen}
              onClose={handleCloseFile}
            />
          )}
        </div>
      )}
    </div>
  );
}

const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
