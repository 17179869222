import React, { createContext, useState, useEffect } from "react";
import { loginApi } from "../api/loginApi"; // 로그인 API 모듈

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [payload, setPayload] = useState(null);

  function decodeJwtPayload(token) {
    if (!token) return null;
    const parts = token.split(".");
    if (parts.length !== 3) return null;

    try {
      const base64 = parts[1].replace(/-/g, "+").replace(/_/g, "/");
      const decodedPayload = atob(base64);
      return JSON.parse(decodedPayload);
    } catch (e) {
      console.error("Failed to decode JWT", e);
      return null;
    }
  }

  useEffect(() => {
    const initAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const kcparams = params.get("kcparams");
      let token = params.get("token");
      try {
        // 쿼리 파라미터로 토큰이 들어온 경우
        if (kcparams) {
          const newToken = await loginApi.verifyParams(kcparams);
          if (!newToken) throw new Error("Token verification failed");

          login(newToken); // 로그인 처리 - 토큰 저장
        } else {
          // 쿼리 parms가 없는 경우
          if (!token) {
            // token이 없는 경우 local token 확인
            // localToken이 없으면 로그인 페이지로 리다이렉트
            const localToken = localStorage.getItem("botkc_token");
            if (
              !localToken ||
              localToken === "null" ||
              localToken === "undefined"
            ) {
              console.log("Redirecting to login page...");
              const current = encodeURIComponent(window.location.href);
              window.location.href = `https://llmchatbot-admin.vercel.app/login?redirect=${current}`;
            } else {
              token = localToken;
            }
          }
          // localStorage에 토큰이 있는 경우
          await loginApi.verify(token);
          login(token); // 인증 상태 업데이트
        }
      } catch (error) {
        console.error("Authentication failed:", error);
        localStorage.removeItem("botkc_token");
        logout();
      }
    };
    initAuth();
  }, []);

  const login = (token) => {
    if (token) {
      localStorage.setItem("botkc_token", token);
      const decodedPayload = decodeJwtPayload(token);
      decodedPayload && setPayload(decodedPayload);
      setIsAuthenticated(true);
    }
  };

  const logout = () => {
    localStorage.removeItem("botkc_token");
    setIsAuthenticated(false);
    setPayload(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, payload, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
