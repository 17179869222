import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ChatContainer.scss";
import ragIcon from "../../images/rag.png";
import devAssistantIcon from "../../images/dev_x.png";
import { IoSend } from "react-icons/io5";
import { FaStopCircle } from "react-icons/fa";
import { GiSpellBook } from "react-icons/gi";
import { FcBookmark } from "react-icons/fc";
import { ImBooks } from "react-icons/im";
import { FaQuestion } from "react-icons/fa";
import Feedback from "../Feedback/Feedback";
// import ChatGuide from "../ChatGuide/ChatGuide";
import TypingIndicator from "./TypingIndicator";
import GeneratingMessage from "../GeneratingMessage/GeneratingMessage";
import Message from "./Message";
import { chatApi } from "../../api/chatApi";

const ChatContainer = ({
  isSidebarOpen,
  isDevMode,
  handleDevMode,
  handleOpenFile,
  isRAG,
  handleOpenRAGChat,
  handleOpenMailChat,
  handleOpenGuide,
  showGuide,
  currentMessages,
}) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const controllerRef = useRef(null);
  const formRef = useRef(null);
  const currentSessionId = useRef(0);
  const isFetchingMessages = useRef(false); // 현재 메시지를 가져오는 중인지 여부를 의미

  //Kc-> KC 대문자로 표기
  function formatCompanyName(company) {
    // 'Kc'로 시작하는 부분을 찾아서 'KC'로 변경
    return company.replace(/Kc/g, "KC");
  }

  const fetchLastSessionId = useCallback(async () => {
    try {
      currentSessionId.current = await chatApi.getLatestSessionId();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // ** 첫 메세지에 session을 통해 히스토리 불러오기 ** //
  const fetchMessages = useCallback(async () => {
    try {
      const messages = await chatApi.getMessages(currentSessionId.current);
      setMessages((prevMessages) => [...prevMessages, ...messages]);
    } catch (error) {
      console.log(error);
    }
  }, [currentSessionId]);

  useEffect(() => {
    if (isFetchingMessages.current) return;
    // 두 번 호출 되지 않도록 방지
    isFetchingMessages.current = true;

    // ** FAQ와 메세지 순서대로 불러오기 ** //
    fetchLastSessionId()
      .then(() => {
        return fetchMessages();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchMessages, fetchLastSessionId]);

  useEffect(() => {
    if (messages.length === 0) {
      const initialMessage = {
        role: "assistant",
        content: `안녕하세요!, 저는 복리후생과 사규 내용과 관련된 질문들에 대답해 드릴 수 있어요. \n\nFAQ가 궁금하시다면 하단의 'FAQ 보기' 버튼을 눌러주세요.`,
        fileViewers: null,
      };

      setMessages([initialMessage]);
    }
  }, [messages.length]);

  // ** 채팅창 자동 올리기 ** //
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [inputMessage]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // ** chat history 표시하기 ** //
  useEffect(() => {
    if (currentMessages) {
      currentSessionId.current = currentMessages.session;
      setMessages(currentMessages.messages); // 세션 대화로 업데이트
    } else {
      setMessages([]); // 초기 화면은 빈 상태
    }
  }, [currentMessages]);

  // ** 답변 로직 ** //
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isGenerating) return;
    setIsGenerating(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: inputMessage },
    ]);
    setInputMessage("");

    const controller = new AbortController();
    controllerRef.current = controller;
    try {
      setIsGenerating(true);
      const response = await chatApi.request(
        {
          session_id: currentSessionId.current,
          content: inputMessage,
        },
        controller
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          references: response.references,
          content: response.answer,
          messageId: response.message_id,
        },
      ]);
    } catch (err) {
      if (err.name !== "AbortError") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content: "질문을 이해하지 못했습니다! 다시 질문해주세요",
          },
        ]);
      }
    } finally {
      setIsGenerating(false);
      controllerRef.current = null;
    }
  };

  const stopGenerating = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
      setIsGenerating(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // FAQ 선택하면 답변
  const onSelectFAQ = useCallback(
    (item) => {
      setInputMessage(item);
      setTimeout(() => {
        if (formRef.current) {
          formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }, 0);
    },
    [formRef]
  );
  // FAQ 보여주기
  const fetchFAQ = useCallback(async () => {
    const question = "어떤 사규 내용들을 참고하고 있어?";
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "user",
        content: question,
      },
    ]);

    try {
      const faqList = await chatApi.getFAQs();

      // FAQ 버튼 생성
      const faqContent = (
        <div>
          <div className="faq-buttons-container">
            {faqList.map((item, index) => (
              <button
                key={`faq-${index}`}
                onClick={() => onSelectFAQ(item)}
                className="faq-button"
              >
                <FcBookmark /> &nbsp; {item}
              </button>
            ))}
          </div>
        </div>
      );

      // FAQ 메시지 생성 및 추가
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          content:
            "아래는 제가 답변해드릴 수 있는 질문 목록입니다. \n\n질문을 클릭해보세요!",
          faqButtons: faqContent,
        },
      ]);
    } catch (error) {
      console.log("Failed to fetch FAQs:", error);
    }
  }, [onSelectFAQ]);

  // 참고한 사규 리스트 api //
  const fetchDocumentList = async () => {
    const question = "어떤 사규 내용들을 참고하고 있어?";
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "user",
        content: question,
      },
    ]);

    try {
      const response = await chatApi.getReferences();
      let content = "📚 참고 문서를 말씀드릴게요.\n\n";
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          references: [],
          content: content + response.join("\n\n"),
        },
      ]);
    } catch (error) {
      console.log("Failed to fetch references:", error);
    }
  };

  // ** 렌더링 ** //
  return (
    <div className={`chat-container ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <div className="chat-header">
        <div id="menu">
          <button id="active" onClick={handleOpenRAGChat}>
            복리후생봇
          </button>
          {/* <button id="deactive" onClick={handleOpenMailChat}>
            메일 검토
          </button> */}
        </div>
        <img
          src={isDevMode ? devAssistantIcon : ragIcon}
          alt="Assistant"
          className="assistant-icon"
          onClick={handleDevMode}
        />
      </div>

      <div className="messages-container">
        {/* <ChatGuide /> */}
        {/* {messages.length === 0 ? (
          <FAQ
            currentServer={currentServer}
            onSelectFAQ={onSelectFAQ}
            company={userInfo.company}
          />
        ) : null} */}
        {showGuide ? null : (
          <>
            {" "}
            {messages.map((msg, index) => (
              <div key={index} className={`message-wrapper ${msg.role}`}>
                <div className={`message ${msg.role}`}>
                  <div className="message-content">
                    <Message
                      content={msg.content}
                      references={msg.references}
                      faqButtons={msg.faqButtons}
                    />
                  </div>
                </div>
                {msg.role === "assistant" &&
                  msg.messageId &&
                  msg.messageId > 0 && (
                    <Feedback messageId={messages[index].messageId} />
                  )}
              </div>
            ))}
            {isGenerating && (
              <div className="message-wrapper assistant">
                <div className="message assistant">
                  <div className="message-content">
                    <TypingIndicator />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>

      <button className="onBoardingGuide" onClick={handleOpenGuide}>
        <GiSpellBook /> &nbsp; 가이드 보기
      </button>

      <button className="onBoardingGuide" onClick={fetchFAQ}>
        <FaQuestion /> &nbsp; FAQ 보기
      </button>

      <button className="onBoardingGuide" onClick={fetchDocumentList}>
        <ImBooks /> &nbsp; 참고한 사규 리스트
      </button>

      <form ref={formRef} className="input-form" onSubmit={handleSubmit}>
        <textarea
          type="text"
          ref={textareaRef}
          value={inputMessage}
          onChange={(e) => {
            setInputMessage(e.target.value);
          }}
          placeholder="복리후생에 관해 궁금한 점을 질문해 주세요"
          disabled={isGenerating}
          rows={1}
          onKeyPress={handleKeyPress}
          maxLength="500"
        />
        {isGenerating ? (
          <button type="button" className="stop" onClick={stopGenerating}>
            <FaStopCircle />
          </button>
        ) : (
          <button type="submit">{<IoSend />}</button>
        )}
      </form>
    </div>
  );
};

export default ChatContainer;
