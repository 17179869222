import { apiClient } from "./apiClient";

export const chatApi = {
  getFAQs: () => apiClient.get(`/chat/faqs`),
  getReferences: () => apiClient.get(`/chat/references`),
  getSessions: () => apiClient.get(`/chat/sessions`),
  getMessages: (sessionId) =>
    apiClient.get(`/chat/sessions/${sessionId}/messages`),
  getLatestSessionId: () => apiClient.get(`/chat/sessions/latest`),

  request: (payload, controller) =>
    apiClient.post(`/chat/request`, payload, {
      signal: controller?.signal,
    }),
  // ✅ 스트리밍 응답용 API
  requestStream: (payload, controller) =>
    apiClient.postStream(`/chat/stream`, payload, {
      signal: controller?.signal,
    }),
};
