const BASE_URL = "https://chatbot.kcsoftmax.com";

async function fetchWithAuth(url, options = {}) {
  const token = localStorage.getItem("botkc_token");

  const headers = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
    ...options.headers,
  };

  const config = {
    ...options,
    headers,
  };

  try {
    const res = await fetch(`${BASE_URL}${url}`, config);

    if (res.status === 401) {
      localStorage.removeItem("botkc_token");
      window.location.href = "/";
      console.warn("Unauthorized access. Redirecting to main page.");
      return;
    }

    if (!res.ok) {
      const errorData = await res.json().catch(() => ({}));
      throw new Error(errorData.message || res.statusText);
    }

    // 스트리밍 모드면 response 자체를 반환
    if (options.stream) {
      return res; // 호출한 쪽에서 reader를 꺼내서 처리
    }

    // 일반 JSON 응답
    const data = await res.json();
    return data;
  } catch (error) {
    throw error;
  }
}

// GET
export const get = (url, params = {}) => {
  const query = new URLSearchParams(params).toString();
  const fullUrl = query ? `${url}?${query}` : url;
  return fetchWithAuth(fullUrl);
};

// POST
export const post = (url, body = {}, config = {}) =>
  fetchWithAuth(url, {
    method: "POST",
    body: JSON.stringify(body),
    ...config,
  });

export const postStream = (url, body = {}, config = {}) =>
  fetchWithAuth(url, {
    method: "POST",
    body: JSON.stringify(body),
    stream: true,
    ...config,
  });
