import React, { useEffect, useState } from "react";
import "./TypingIndicator.scss";

const TypingIndicator = () => {
  const fullText = "답 변을 생각 중입니다...";
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + fullText.charAt(index));
      index++;
      if (index >= fullText.length) clearInterval(interval);
    }, 60); // 타자 속도 (ms)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="typing-indicator">
      <div className="dots">
        <span className="dot" />
        <span className="dot" />
        <span className="dot" />
      </div>
      <span className="thinking-text">{displayedText}</span>
    </div>
  );
};

export default TypingIndicator;
